@import "../../styles/variables";

.login-form {
  background: #FFFFFF;
  max-width: 22.5rem;
  padding: 30px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__btn {
    padding: 15px;
    width: 100%;
    border-radius: 10px;
  }

}
