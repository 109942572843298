.logOut-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  &__img {
    width: 1.875rem;
  }
}
