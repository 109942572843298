.activate-user-form {
  background-color: #fff;
  border-radius: 5px;
  max-width: 20%;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px;
    width: 100%;
  }

  &__error {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__button {
    padding: 10px;
    width: 100%;
  }

}
