@import '../../styles/variables';

.filter-orders {
  display: flex;
  padding: 5px;

  &__inputs {
    display: flex;
    flex-wrap: wrap;
  }

  &__input {
    padding: 3px 5px;
    margin: 0 auto;
    width: calc(16.66%)
  }

  &__checkbox-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    gap: 10px;
  }

  label {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__checkbox {
    width: 1.25rem;
    height: 1.25rem;
  }

  &__checkbox:checked {
    accent-color: #43A047;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  &__button-img {
    width: 1.625rem;
  }

}
