.orders-page{
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__management{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
  }

  &__content{
    flex-grow: 1;
  }

}

