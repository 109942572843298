@import "../../styles/variables.scss";

.comment {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;

  &:last-child {
    border-bottom: none;
  }

  &__addition-info {
    display: flex;
    gap: 10px;
    justify-content: flex-end;

  }

}
