@import "../../styles/variables";

.header {
  background-color: $main-color;
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 10px
  }

  &__name {
    font-size: 1.5rem;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  &__button_hidden {
    display: none;
  }

  &__button-img {
    width: 1.875rem;
  }

}
