@import "../../styles/variables";



.client-form {
  width: 60%;
  max-height: 98%;
  overflow: auto;
  color: black;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 2;
  border-radius: 10px;
  padding: 20px 20px;

&::-webkit-scrollbar{
  border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  background: $main-color;
    width: 10px;
}

    &::-webkit-scrollbar-thumb{
        background: $second-color;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

  &__inputs {
    display: flex;
    flex-wrap: wrap;
  }

  &__item{
    margin: 10px;
    width: calc(50% - 2 * 10px);
    display: flex;
    flex-direction: column;
  }

  &__input-buttons{
    display: flex;
  }

  &__input-button{
    padding: 3px 10px;
    width: 100%;
  }

  &__btns{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  &__btn{
    padding: 10px;
  }



}

