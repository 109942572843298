@import "../../styles/variables";

.comment-form {
  background-color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  gap: 10px;

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;

  }

  &__button {
    padding: 15px;

    &_disabled {
      cursor: not-allowed;
      background-color: #C0C0C0FF;

      &:hover {
        background-color: #C0C0C0FF;
      }

    }

  }

}
