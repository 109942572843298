@import '../../styles/variables.scss';

.page404 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $main-color;

  &__img {
    width: 45%
  }

}
