@import "../../styles/variables";

.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;

  &__item {
    padding: 0 12px;
    height: 2rem;
    text-align: center;
    margin: auto 4px;
    color: white;
    background-color: $main-color;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    min-width: 2rem;

    &.dots:hover {
      background-color: $hover-color;
      cursor: default;
    }

    &:hover {
      background-color: $hover-color;
      cursor: pointer;
    }

    &.selected {
      background-color: $hover-color
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12rem solid #FFFFFF;
        border-top: 0.12rem solid #FFFFFF;
      }

      &:hover {
        cursor: default;
      }
    }
  }

  &__arrow {
    width: 1.25rem
  }

}
