@import "./variables.scss";



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

button {
  text-transform: uppercase;
  background: $second-color;
  outline: 0;
  border: none;
  color: #FFFFFF;
  border-radius: 5px;
  transition: all 0.3s ease;
  &:hover {
    background: $hover-color;
  }

}
