@import '../../styles/variables';


.orders-table {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: table;
  width: 100%;

  &__row {
    display: table-row;
    background: #f6f6f6;
    position: relative;

    &_active {
      height: 17.1875rem;
    }

    &:nth-of-type(odd) {
      background: #e9e9e9;
    }

    &_hover:hover {
      background: $second-color;
      color: #FFFFFF;
    }

    &.orders-table__header {
      font-weight: 700;
      color: #ffffff;
      background: $main-color;
    }

  }

  &__cell {
    padding: 5px;
    display: table-cell;
    cursor: pointer;
  }

  &__details {
    display: none;
  }

  &__details_visible {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    padding: 0 20px;
  }

  &__details-left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  &__details-right {
    display: flex;
    align-items: center;
    width: 100%;
    color: black;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin: 0 20px;

  }

  &__comments {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2px;
    cursor: pointer;
    background-color: #ffffff;
    padding: 5px 10px;
    border-radius: 10px;
  }

  &__button {
    padding: 10px;
    width: 10%;

    &_disabled {
      cursor: not-allowed;
      background-color: #C0C0C0FF;

      &:hover {
        background-color: #C0C0C0FF;
      }
    }

  }
}







