@import '../../styles/variables.scss';

.user {
  display: flex;
  gap: 40px;
  padding: 20px;
  border: 2px solid $main-color;
  border-radius: 10px;

  &__info {
    display: flex;
    flex-direction: column;
    width: 12.5rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__button {
    padding: 10px;
  }

}
