.form-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  &__input {
    outline: none;
    background: #eeeeee;
    border: 2px solid #eeeeee;
    padding: 15px;
    font-size: 0.875rem;
    border-radius: 10px;
    transition: 1s;

    &_red {
      border: 2px solid #e00000;
    }
  }

}
