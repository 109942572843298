.admin-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 10px 100px;
    gap: 10px;
  }

  &__statistic {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    align-items: center;
    gap: 5px;
    font-size: 1.125rem;
  }

  &__button {
    padding: 10px;
  }

  &__users {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

}
