.excel-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  &__img {
    width: 1.625rem;
  }

}
